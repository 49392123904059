import React from 'react';
import '../../styles/home_style/Home.scss';
import Sidebar from '../../components/Sidebar';
import { Link } from 'react-router-dom';
import icon1 from '../../assets/images/icon_user_module.svg';
import icon2 from '../../assets/images/icon_clients_module.svg';
import icon3 from '../../assets/images/icon_machinery_module.svg';
import icon4 from '../../assets/images/icon_job_module.svg';
import icon5 from '../../assets/images/icon_inventory_module.svg';
import icon6 from '../../assets/images/icon_providers_module.svg';
import icon7 from '../../assets/images/icon_purchase_order_module.svg';

// Module mapping to content types
const MODULE_PERMISSIONS = {
  user: "User Module",      // Users content type
  clients: "Clients Module",   // Clients content type
  machinery: "Machinery Module", // Machinery content type
  job: "Job Module",      // Jobs content type
  inventory: "Inventory Module", // Inventory content type
  providers: "Provider Module", // Providers content type
  purchase_orders: "Purchase Order Module" // Purchase Orders content type
};

function Home() {
  const userPermissions = JSON.parse(localStorage.getItem('userPermissions') || '[]');
  const userName = localStorage.getItem('userName') || 'User';
  const userRole = localStorage.getItem('userRole');

  const hasPermission = (moduleKey) => {
    return userPermissions.includes(MODULE_PERMISSIONS[moduleKey]);
  };

  const modules = [
    { path: '/user', icon: icon1, name: 'User Module', key: 'user' },
    { path: '/clients', icon: icon2, name: 'Clients Module', key: 'clients' },
    { path: '/machinery', icon: icon3, name: 'Machinery Module', key: 'machinery' },
    { path: '/job', icon: icon4, name: 'Job Module', key: 'job' },
    { path: '/inventory', icon: icon5, name: 'Inventory', key: 'inventory' },
    { path: '/providers', icon: icon6, name: 'Providers', key: 'providers' },
    { path: '/purchase-orders', icon: icon7, name: 'Purchase Order', key: 'purchase_orders' }
  ];

  return (
    <div className="home-container">
      <Sidebar userType={userRole} />
      <div className="home-content">
        <h1>Welcome {userName}</h1>
        <div className="modules-grid">
          {modules.map((module) => 
            hasPermission(module.key) && (
              <Link key={module.path} to={module.path} className="module-card">
                <img src={module.icon} alt={`${module.name} Icon`} className="module-icon" />
                <span className="module-name">{module.name}</span>
              </Link>
            )
          )}
        </div>
      </div>
    </div>
  );
}

export default Home;