import React from 'react';
import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ children, userType }) => {
  const userRole = localStorage.getItem('userRole');
  
  if (!userRole || (userType && userRole !== userType)) {
    // Redirect to login if user is not authenticated or doesn't have the required role
    return <Navigate to="/" replace />;
  }

  return children;
};

export default ProtectedRoute; 
