import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const QuickBooksCallback = () => {
  useEffect(() => {
    const processCallback = () => {
      const urlParams = new URLSearchParams(window.location.search);
      const code = urlParams.get('code');
      const realmId = urlParams.get('realmId');
      const state = urlParams.get('state');

      console.log('Processing QuickBooks callback');

      // If this window was opened by another window
      if (window.opener && !window.opener.closed) {
        console.log('Sending callback data to parent window');
        window.opener.postMessage({
          type: 'QB_AUTH_CALLBACK',
          code,
          realmId,
          state
        }, window.location.origin);
      }
    };

    processCallback();
  }, []);

  return <div>Processing QuickBooks authorization...</div>;
};

export default QuickBooksCallback; 