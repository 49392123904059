import React, { useState, useEffect } from 'react';
import { TextField, IconButton } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import api from '../../utils/api';
import JobCard from '../../components/JobCard';
import Sidebar from '../../components/Sidebar';
import '../../styles/job_module_style/TechnicianJobModule.scss';

const TechnicianJobModule = () => {
  const [unassignedJobs, setUnassignedJobs] = useState([]);
  const [myJobs, setMyJobs] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedDate, setSelectedDate] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [machineries, setMachineries] = useState([]);
  const [status, setStatus] = useState([]);

  // Get current user ID (you'll need to implement your auth logic)
  const currentTechnicianId = "current-user-id"; 

  useEffect(() => {
    fetchJobs();
    fetchMachineries();
    fetchStatus();
  }, []);

  const fetchJobs = async () => {
    try {
      const response = await api.get('/activities/');
      const allJobs = response.data;
      
      // Filter unassigned jobs
      const unassigned = allJobs.filter(job => 
        job.id_status === getStatusIdByName("Job Unassigned")
      );
      
      // Filter jobs assigned to current technician
      const assigned = allJobs.filter(job => 
        job.technician_id === currentTechnicianId
      );

      setUnassignedJobs(unassigned);
      setMyJobs(assigned);
      setLoading(false);
    } catch (err) {
      setError('Error fetching jobs');
      setLoading(false);
    }
  };

  const fetchMachineries = async () => {
    try {
      const response = await api.get('/machinery/');
      setMachineries(response.data);
    } catch (err) {
      console.error('Error fetching machineries:', err);
    }
  };

  const fetchStatus = async () => {
    try {
      const response = await api.get('/status/');
      setStatus(response.data);
    } catch (err) {
      console.error('Error fetching status:', err);
    }
  };

  const handleAssignToMe = async (jobId) => {
    try {
      // Update job status to assigned
      await api.put(`/activities/${jobId}/`, {
        id_status: getStatusIdByName("Job Assigned")
      });

      // Create activity-user association
      await api.post('/activity-users/', {
        id_user: currentTechnicianId,
        id_activity: jobId,
        last_work: false
      });

      // Refresh jobs
      fetchJobs();
    } catch (err) {
      setError('Error assigning job');
    }
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleDateChange = (e) => {
    setSelectedDate(e.target.value);
  };

  const getStatusIdByName = (statusName) => {
    const statusItem = status.find((item) => item.name === statusName);
    return statusItem ? statusItem.id : null;
  };

  const getMachineryInfo = (machineryId) => {
    const machinery = machineries.find(m => m.id === machineryId);
    return machinery ? 
      `${machinery.unit} ${machinery.model} | ${machinery.serial_number}` : 
      'Machinery not found';
  };

  const filteredMyJobs = myJobs.filter(job => {
    const matchesSearch = job.type.toLowerCase().includes(searchTerm.toLowerCase()) ||
                         job.description.toLowerCase().includes(searchTerm.toLowerCase());
    const matchesDate = !selectedDate || job.work_date === selectedDate;
    return matchesSearch && matchesDate;
  });

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div className="technician-job-module">
      <Sidebar userType="technician" />
      
      {/* Unassigned Jobs Section */}
      <div className="unassigned-jobs-section">
        <h2 className="section-header">Unassigned Jobs Nearing Deadline</h2>
        <div className="unassigned-jobs-list">
          {unassignedJobs.map(job => (
            <div key={job.id} className="unassigned-job-card">
              <div className="description">{job.description}</div>
              <div className="machinery-info">{getMachineryInfo(job.id_machinery)}</div>
              <button 
                className="assign-button"
                onClick={() => handleAssignToMe(job.id)}
              >
                Assign to me
              </button>
              <span className="date-indicator">{job.work_date}</span>
            </div>
          ))}
        </div>
      </div>

      {/* My Jobs Section */}
      <div className="my-jobs-section">
        <div className="section-header">
          <h2>My Jobs</h2>
          <div className="filters">
            <TextField
              placeholder="Search jobs..."
              variant="outlined"
              size="small"
              value={searchTerm}
              onChange={handleSearch}
              InputProps={{
                endAdornment: <SearchIcon />
              }}
            />
            <TextField
              type="date"
              variant="outlined"
              size="small"
              value={selectedDate}
              onChange={handleDateChange}
              InputProps={{
                endAdornment: <CalendarTodayIcon />
              }}
            />
          </div>
        </div>

        <div className="jobs-grid">
          {filteredMyJobs.map(job => (
            <JobCard
              key={job.id}
              jobName={job.type}
              description={job.description}
              machinery={getMachineryInfo(job.id_machinery)}
              date={job.work_date}
              status={job.id_status}
              onClick={() => {/* Handle job card click */}}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default TechnicianJobModule;
