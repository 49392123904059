import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import '../styles/components/InvoiceGenerationPopup.scss';
import SearchIcon from '@mui/icons-material/Search';
import { TextField, IconButton } from '@mui/material';
import api from '../utils/api';
import quickbooksService from '../services/quickbooksService';


const InvoiceGenerationPopup = ({ job, onBack, onCreateInvoice, isOpen }) => {
  const [clientData, setClientData] = useState(null);
  const [machineryData, setMachineryData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchRelatedData = async () => {
      if (!job) return;
      
      try {
        setLoading(true);
        // Fetch client and machinery data in parallel
        const [clientResponse, machineryResponse] = await Promise.all([
          api.get(`/clients/${job.id_client}/`),
          api.get(`/machinery/${job.id_machinery}/`)
        ]);

        setClientData(clientResponse.data);
        setMachineryData(machineryResponse.data);
        setError(null);
      } catch (err) {
        console.error('Error fetching related data:', err);
        setError('Error loading data');
      } finally {
        setLoading(false);
      }
    };

    fetchRelatedData();
  }, [job]);

  const handleQuickBooksConnect = async () => {
    try {
      console.log('Starting QuickBooks connection process');
      const result = await quickbooksService.completeQuickBooksConnection(job.id);
      console.log('QuickBooks connection result:', result);
    } catch (error) {
      console.error('Error connecting to QuickBooks:', error);
      // Handle error appropriately
    }
  };

  const handleCreateInvoice = async () => {
    try {
      // Prepare invoice data
      const invoiceData = {
        // ... format your invoice data here
        customerId: job.id_client,
        items: [], // Add your line items
        amount: 0, // Calculate total
        // ... other invoice details
      };

      // Create invoice in QuickBooks
      await quickbooksService.createInvoice(invoiceData);
      onCreateInvoice();
    } catch (error) {
      console.error('Error creating invoice:', error);
      // Handle error (show error message to user)
    }
  };

  if (!isOpen || !job) return null;
  if (loading) return <div className="invoice-generation-popup">Loading...</div>;
  if (error) return <div className="invoice-generation-popup">Error: {error}</div>;

  // Create a formatted machinery string for the header
  const machineryString = machineryData ? 
    `${machineryData.unit} ${machineryData.model} | ${machineryData.serial_number}` : 
    'Loading...';

  // Create a formatted model/SN string for the form field
  const modelSnString = machineryData ? 
    `${machineryData.unit} ${machineryData.model} / ${machineryData.serial_number}` : 
    'Loading...';

  return (
    <div className="invoice-generation-popup">
      <div className="invoice-content">
        <div className="invoice-header">
          <h2>Invoice Generation - {job.type}</h2>
          <div className="header-info">
            <div className="client-info">
              <div className="info-item">
                <span className="icon">👤</span> {clientData?.client_name || 'Loading...'}
              </div>
              <div className="info-item">
                <span className="icon">✉️</span> {clientData?.email || 'Loading...'}
              </div>
            </div>
            <div className="machinery-info">
              <div className="info-item">
                <span className="icon">🔧</span> {machineryString}
              </div>
              <div className="info-item">
                <span className="icon">🛠️</span> {job.type}
              </div>
            </div>
          </div>
        </div>

        <div className="invoice-body">
          {/* Left Column */}
          <div className="left-column">
            <div className="search-and-actions">
              <TextField
                fullWidth
                placeholder="Search..."
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <IconButton>
                      <SearchIcon />
                    </IconButton>
                  ),
                }}
                className="search-input"
              />
              <div className="action-buttons">
                <button className="delete-btn">
                  <span className="icon">🗑️</span>
                </button>
                <button className="edit-btn">
                  <span className="icon">✏️</span>
                </button>
                <button className="add-btn">
                  <span className="icon">➕</span>
                </button>
              </div>
            </div>

            <div className="invoice-table">
              <div className="table-header">
                <div className="col">Type</div>
                <div className="col">Name</div>
                <div className="col">Description</div>
                <div className="col">Quantity</div>
                <div className="col">Hourly Rate/Each</div>
                <div className="col">Line Total</div>
              </div>
              <div className="table-body">
                {/* Table rows will be added here */}
              </div>
            </div>

            <div className="comments-section">
              <label>Comments</label>
              <textarea rows="4"></textarea>
            </div>
          </div>

          {/* Right Column */}
          <div className="right-column">
            <div className="invoice-details">
              <div className="form-group">
                <label>Invoice Number</label>
                <input type="text" readOnly />
              </div>
              <div className="form-group">
                <label>Date</label>
                <input 
                  type="date" 
                  value={job.work_date}
                  readOnly 
                />
              </div>
              <div className="form-group">
                <label>Bill to</label>
                <input 
                  type="text" 
                  value={clientData?.billing_address || ''}
                  readOnly
                />
              </div>
              <div className="form-group">
                <label>Payment Terms</label>
                <select>
                  <option value="">Select terms</option>
                </select>
              </div>
              <div className="form-group">
                <label>Hour Meter</label>
                <input type="text" />
              </div>
              <div className="form-group">
                <label>Fleet Number</label>
                <input type="text" />
              </div>
              <div className="form-group">
                <label>Type</label>
                <select>
                  <option value="">Select type</option>
                </select>
              </div>
              <div className="form-group">
                <label>Model / SN</label>
                <input 
                  type="text" 
                  value={modelSnString}
                  readOnly
                />
              </div>

              <div className="totals-section">
                <div className="total-row">
                  <label>Tax (XX%)</label>
                  <span>XXXX</span>
                </div>
                <div className="total-row">
                  <label>Total</label>
                  <span>XXXX</span>
                </div>
                <div className="total-row">
                  <label>Payments Applied</label>
                  <span>XXXX</span>
                </div>
                <div className="total-row">
                  <label>Balance Due</label>
                  <span>XXXX</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="button-container">
          <button className="back-btn" onClick={onBack}>Back</button>
          <button 
            className="quickbooks-btn" 
            onClick={handleQuickBooksConnect}
          >
            Connect to QuickBooks
          </button>
          <button 
            className="create-invoice-btn" 
            onClick={handleCreateInvoice}
          >
            Create Invoice
          </button>
        </div>
      </div>
    </div>
  );
};

InvoiceGenerationPopup.propTypes = {
  job: PropTypes.object.isRequired,
  onBack: PropTypes.func.isRequired,
  onCreateInvoice: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
};

export default InvoiceGenerationPopup; 