import axios from 'axios';
import api from '../utils/api';

const QB_CONFIG = {
  clientId: "AB5FLY6pFpfEVy1fnCWIfiSWfEyw8AadoDY0IOq1TFgtdjSgxG",
  clientSecret: "kqqpA7c3n8JbzWgfBVdUnVPraLhmGZOi5Z7ce1cm",
  environment: "sandbox",
  redirectUri: "https://5or7rsytse.execute-api.us-east-1.amazonaws.com/qa/oauth/intuit/callback/"
};

class QuickBooksService {
  constructor() {
    this.authEndpoint = 'https://appcenter.intuit.com/app/connect/oauth2';
    this.tokenEndpoint = 'https://oauth.platform.intuit.com/oauth2/v1/tokens/bearer';
    this.apiBase = QB_CONFIG.environment === 'sandbox' 
      ? 'https://sandbox-quickbooks.api.intuit.com/v3/company'
      : 'https://quickbooks.api.intuit.com/v3/company';
  }

  async initiateConnection(jobId) {
    try {
      console.log('STEP 1: Starting QuickBooks authorization process', { jobId });
      const userId = localStorage.getItem('userId');
      console.log(userId)
      const params = {
        client_id: QB_CONFIG.clientId,
        response_type: 'code',
        scope: 'com.intuit.quickbooks.accounting openid',
        redirect_uri: QB_CONFIG.redirectUri,
        state: userId
      };

      console.log('STEP 1a: OAuth parameters prepared:', params);

      const authUrl = `${this.authEndpoint}?client_id=${params.client_id}&response_type=${params.response_type}&scope=${encodeURIComponent(params.scope)}&redirect_uri=${encodeURIComponent(params.redirect_uri)}&state=${encodeURIComponent(params.state)}`;
      
      console.log('STEP 1b: Authorization URL generated:', authUrl);

      // Store data for the callback phase
      sessionStorage.setItem('qb_pending_connection', JSON.stringify({
        jobId,
        timestamp: new Date().toISOString()
      }));
      console.log('STEP 2: Stored connection data in sessionStorage');

      // Open QuickBooks authorization in a new window with specific features
      console.log('STEP 3: Opening QuickBooks authorization in new window');
      const authWindow = window.open(
        authUrl,
        'QuickBooks Authorization',
        'width=600,height=800,menubar=no,toolbar=no,location=no,resizable=yes,scrollbars=yes,status=no'
      );
      console.log('STEP 3a: Auth window opened');

      // Check if popup was blocked
      if (authWindow === null) {
        throw new Error('Popup window was blocked. Please allow popups for this site.');
      }

      // Set focus to the popup window
      if (authWindow) {
        authWindow.focus();
      }

      // Poll the popup window to check its URL
      const pollTimer = setInterval(() => {
        try {
          if (authWindow.closed) {
            clearInterval(pollTimer);
            console.log('STEP 3b: Auth window was closed by user');
            console.log(currentUrl)
            return;
          }

          const currentUrl = authWindow.location.href;
          console.log('STEP 3c: Checking auth window URL:', currentUrl);

          if (currentUrl.includes('code=')) {
            clearInterval(pollTimer);
            const urlParams = new URLSearchParams(new URL(currentUrl).search);
            const code = urlParams.get('code');
            const realmId = urlParams.get('realmId');
            const state = urlParams.get('state');

            console.log('STEP 4: Received authorization code');
            authWindow.close();

            // Process the authorization response
            this.handleCallback(code, realmId, state);
          }
        } catch (e) {
          // Ignore cross-origin errors while polling
          if (!e.toString().includes('cross-origin')) {
            console.error('Error polling auth window:', e);
          }
        }
      }, 500);

    } catch (error) {
      console.error('ERROR in QuickBooks initiation:', error);
      throw error;
    }
  }

  async handleCallback(code, realmId, state) {
    try {
      console.log('STEP 5: Token exchange completed:', {
        status: 'success',
        hasAccessToken: !!code
      });

      if (code) {
        localStorage.setItem('qb_access_token', code);
        localStorage.setItem('qb_realm_id', realmId);
        console.log('STEP 6: Tokens stored successfully');

        return {
          status: 'success',
          message: 'QuickBooks connection completed successfully',
          realmId: realmId
        };
      }
    } catch (error) {
      console.error('ERROR in QuickBooks callback:', error);
      throw error;
    }
  }

  async completeQuickBooksConnection(jobId) {
    if (window.location.search.includes('code=')) {
      return this.handleCallback();
    } else {
      return this.initiateConnection(jobId);
    }
  }

  async getCompanyInfo() {
    try {
      const realmId = localStorage.getItem('qb_realm_id');
      const response = await axios.get(`/api/quickbooks/company/${realmId}/companyinfo`);
      return response.data;
    } catch (error) {
      console.error('Error getting company info:', error);
      throw error;
    }
  }

  async createInvoice(invoiceData) {
    try {
      const realmId = localStorage.getItem('qb_realm_id');
      const response = await axios.post(`/api/quickbooks/company/${realmId}/invoice`, invoiceData);
      return response.data;
    } catch (error) {
      console.error('Error creating invoice:', error);
      throw error;
    }
  }

  async getCustomer(customerId) {
    try {
      const realmId = localStorage.getItem('qb_realm_id');
      const response = await axios.get(`/api/quickbooks/company/${realmId}/customer/${customerId}`);
      return response.data;
    } catch (error) {
      console.error('Error getting customer:', error);
      throw error;
    }
  }

  async createCustomer(customerData) {
    try {
      const realmId = localStorage.getItem('qb_realm_id');
      const response = await axios.post(`/api/quickbooks/company/${realmId}/customer`, customerData);
      return response.data;
    } catch (error) {
      console.error('Error creating customer:', error);
      throw error;
    }
  }

  isConnected() {
    return !!localStorage.getItem('qb_access_token');
  }

  disconnect() {
    localStorage.removeItem('qb_access_token');
    localStorage.removeItem('qb_realm_id');
  }
}

export default new QuickBooksService(); 