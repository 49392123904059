import React from 'react';
import PropTypes from 'prop-types';
import '../styles/components/JobCard.scss';

import CalendarMonthRoundedIcon from '@mui/icons-material/CalendarMonthRounded';

const JobCard = ({ jobName, status, description, machinery, date, statusColor, isSelected, onClick, onDoubleClick }) => {
    return (
        <div 
            className={`job-card ${isSelected ? 'selected' : ''}`} 
            style={{ backgroundColor: statusColor }}
            onClick={onClick}
            onDoubleClick={(e) => {
                e.stopPropagation();
                onDoubleClick();
            }}
        >
            <div className="job-card-header" style={{ backgroundColor: statusColor }}>
                <h3>{jobName}</h3>
                <span className="status">({status})</span>
            </div>

            <div className="job-card-body">
                <div className="job-info">
                    <p><strong>Description:</strong> {description || 'No description available'}</p>
                    <p><strong>Machinery:</strong> {machinery || 'No machinery specified'}</p>
                </div>
                <div className="job-card-footer">
                    <CalendarMonthRoundedIcon className="calendar-icon" /> {date ? date : '--/--/--'}
                </div>
            </div>
        </div>
    );
};

JobCard.propTypes = {
    jobName: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    description: PropTypes.string,
    machinery: PropTypes.string,
    date: PropTypes.string,
    statusColor: PropTypes.string,
    isSelected: PropTypes.bool,
    onClick: PropTypes.func,
    onDoubleClick: PropTypes.func,
};

export default JobCard;
