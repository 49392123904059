import React from 'react';
import '../../styles/pages/QuickBooksSuccess.scss';

const QuickBooksSuccess = () => {
  const handleClose = () => {
    try {
      window.close();
    } catch (e) {
      console.log('Could not close window automatically');
    }
  };

  return (
    <div className="quickbooks-success" style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      minHeight: '100vh',
      backgroundColor: '#f5f5f5',
      fontFamily: 'Arial, sans-serif'
    }}>
      <div className="success-content" style={{
        textAlign: 'center',
        padding: '2rem',
        backgroundColor: 'white',
        borderRadius: '8px',
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
        maxWidth: '400px',
        width: '90%'
      }}>
        <div className="checkmark" style={{
          fontSize: '48px',
          color: '#4CAF50',
          marginBottom: '1rem'
        }}>✓</div>
        <h1 style={{
          color: '#333',
          fontSize: '24px',
          marginBottom: '1rem'
        }}>Successfully Connected to QuickBooks!</h1>
        <p style={{
          color: '#666',
          fontSize: '16px',
          marginBottom: '1.5rem'
        }}>You can now close this window and return to the application.</p>
        
      </div>
    </div>
  );
};

export default QuickBooksSuccess; 