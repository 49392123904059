import React, { useState, useEffect } from 'react';
import '../../styles/user_module_style/UserModule.scss';
import Sidebar from '../../components/Sidebar';
import api from '../../utils/api';
import { TextField, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Box } from "@mui/material";

import icon1 from '../../assets/images/icon_delete.svg';
import icon2 from '../../assets/images/icon_edit.svg';
import icon3 from '../../assets/images/icon_add.svg';

import SearchIcon from "@mui/icons-material/Search";
import { TablePagination } from '@mui/material';


const UserModule = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [editingUser, setEditingUser] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const [deletingUser, setDeletingUser] = useState(null);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const [passwordRequirements, setPasswordRequirements] = useState({
    length: false,
    uppercase: false,
    lowercase: false,
    number: false,
    specialChar: false
  });
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [groups, setGroups] = useState({});
  const [currentPage, setCurrentPage] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(7);

  // edit popup
  const [showEditConfirmation, setShowEditConfirmation] = useState(false);
  const [editActionType, setEditActionType] = useState('');

  const fetchGroups = async () => {
    try {
      const response = await api.get('/groups/');
      const groupsData = {};
      response.data.forEach(group => {
        groupsData[group.id] = group.name;
      });
      setGroups(groupsData);
    } catch (err) {
      console.error('Error fetching groups:', err);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        await fetchGroups();
        const response = await api.get('/users');

        console.log(response.data);

        setUsers(response.data);
        setFilteredUsers(response.data);
        setLoading(false);
      } catch (err) {
        setError('Error fetching data');
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const handleRowClick = (userId) => {
    setSelectedRow(selectedRow === userId ? null : userId);
  };

  const handleEdit = async () => {
    if (selectedRow !== null) {
      try {
        const response = await api.get(`/users/${selectedRow}/`);
        setEditingUser(response.data);
        setIsEditing(true);
      } catch (error) {
        console.error('Error fetching user details:', error);
        // You might want to show an error message to the user here
      }
    }
  };

  const PasswordRequirements = ({ requirements }) => (
    <div className="password-requirements">
      <p>Password must contain:</p>
      <ul>
        <li className={requirements.length ? 'met' : ''}>At least 8 characters</li>
        <li className={requirements.uppercase ? 'met' : ''}>1 uppercase letter</li>
        <li className={requirements.lowercase ? 'met' : ''}>1 lowercase letter</li>
        <li className={requirements.number ? 'met' : ''}>1 number</li>
        <li className={requirements.specialChar ? 'met' : ''}>1 special character</li>
      </ul>
    </div>
  );

  const handleEditChange = (e) => {
    const { name, value } = e.target;
    if (name === 'groups') {
      setEditingUser({ ...editingUser, [name]: [parseInt(value, 10)] });
    } else {
      setEditingUser({ ...editingUser, [name]: value });
    }

    if (name === 'password') {
      const requirements = {
        length: value.length >= 8,
        uppercase: /[A-Z]/.test(value),
        lowercase: /[a-z]/.test(value),
        number: /\d/.test(value),
        specialChar: /[!@#$%^&*(),.?":{}|<>]/.test(value)
      };
      setPasswordRequirements(requirements);
    }
  };

  const handleSave = async () => {
    if (editingUser) {
      try {
        let response;
        let dataToSend = { ...editingUser };
        if (!isCreating) {
          delete dataToSend.password;
        }
        if (isCreating) {
          response = await api.post('/users/', dataToSend);
          setEditActionType('create');
        } else {
          response = await api.put(`/users/${editingUser.id}/`, dataToSend);
          setEditActionType('edit');
        }
        const updatedUser = response.data;

        let updatedUsers;
        if (isCreating) {
          updatedUsers = [...users, updatedUser];
        } else {
          updatedUsers = users.map(user => 
            user.id === updatedUser.id ? updatedUser : user
          );
        }
        setUsers(prevUsers => {
          if (isCreating) {
            return [...prevUsers, updatedUser];
          } else {
            return prevUsers.map(user => 
              user.id === updatedUser.id ? updatedUser : user
            );
          }
        });

        setFilteredUsers(prevUsers => {
          if (isCreating) {
            return [...prevUsers, updatedUser];
          } else {
            return prevUsers.map(user => 
              user.id === updatedUser.id ? updatedUser : user
            );
          }
        });

        setIsEditing(false);
        setIsCreating(false);
        setEditingUser(null);
        setError('');

        // Mostrar el popup de confirmación
        setShowEditConfirmation(true);

      } catch (error) {
        console.error('Error saving user:', error);
        setError('An error occurred while saving the user. Please try again.');
      }
    }
  };

  const handleDelete = () => {
    if (selectedRow !== null) {
      const userToDelete = users.find(user => user.id === selectedRow);
      setDeletingUser(users[selectedRow]);
      setIsDeleting(true);
      //console.log(deletingUser);
    }
  };

  const confirmDelete = async () => {
    if (deletingUser) {
      try {
        await api.post(`/users/${deletingUser.id}/deactivate/`);
        setUsers(prevUsers => prevUsers.filter(user => user.id !== deletingUser.id));
        setFilteredUsers(prevUsers => prevUsers.filter(user => user.id !== deletingUser.id));
  
        setIsDeleting(false);
        setDeletingUser(null);
        setSelectedRow(null);
        setShowConfirmation(true);
      } catch (error) {
        console.error('Error deleting user:', error);
      }
    }
  };

  const handleConfirmationClose = () => {
    setShowConfirmation(false);
  };

  const handleCreate = () => {
    setEditingUser({
      username: '',
      email: '',
      password: '',
      first_name: '',
      last_name: '',
      groups: [],
      user_permissions: []
    });
    setIsCreating(true);
  };

  const validatePassword = (password) => {
    const minLength = 8;
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasNumber = /\d/.test(password);
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);

    return (
      password.length >= minLength &&
      hasUpperCase &&
      hasLowerCase &&
      hasNumber &&
      hasSpecialChar
    );
  };

  const handleSearch = (e) => {
    const term = e.target.value.toLowerCase();
    setSearchTerm(term);

    const filtered = users.filter(user => 
      Object.values(user).some(value => 
        value.toString().toLowerCase().includes(term)
      )
    );

    setFilteredUsers(filtered);
  };


  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const validUsers = filteredUsers.filter(user => user.groups.length > 0 && groups[user.groups[0]]);

  const paginatedUsers = validUsers.slice(
    currentPage * rowsPerPage,
    currentPage * rowsPerPage + rowsPerPage
  );

  return (
    <div className="user-module-container">
      <Sidebar userType="admin" />
      <div className="user-module">
        <h1>User Module</h1>
        <Box className="action-container">
          <TextField
            fullWidth
            placeholder="Search..."
            variant="outlined"
            InputProps={{
              endAdornment: (
                <IconButton className="search-button">
                  <SearchIcon />
                </IconButton>
              ),
            }}
            className="search-input" onChange={handleSearch}
          />
          
          <IconButton className="delete-button" onClick={handleDelete}>
            <img src={icon1} alt="Delete" />
          </IconButton>

          <IconButton className="edit-button" onClick={handleEdit}>
            <img src={icon2} alt="Edit" />
          </IconButton>
          
          <IconButton className="add-button" onClick={handleCreate}>
            <img src={icon3} alt="Add" />
          </IconButton>
        </Box>

        {loading ? (
          <p>Loading users...</p>
        ) : error ? (
          <p>{error}</p>
        ) : (

          <>
          <TableContainer className="user-table-container" component={Paper}>
            <Table className="user-table">
              <TableHead>
                <TableRow>
                  <TableCell>First name</TableCell>
                  <TableCell>Last name</TableCell>
                  <TableCell>Username</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Role</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {paginatedUsers.map((user) => (
                  user.groups.length > 0 && groups[user.groups[0]] && 
                  <TableRow 
                    key={user.id}
                    onClick={() => handleRowClick(user.id)}
                    className={selectedRow === user.id  ? 'selected' : ''}>
                    <TableCell>{user.first_name}</TableCell>
                    <TableCell>{user.last_name}</TableCell>
                    <TableCell>{user.username}</TableCell>
                    <TableCell>{user.email}</TableCell>
                    <TableCell className="role-badge"> 
                      <span> {groups[user.groups[0]] || 'Unknown'}</span> 
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          
          <TablePagination
            rowsPerPageOptions={[7, 10, 25]}
            component="div"
            count={validUsers.length}
            rowsPerPage={rowsPerPage}
            page={currentPage}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </>
      )}

      </div>
      {(isEditing || isCreating) && (
        <div className="edit-popup">
          <div className="edit-popup-content">
            <h2>{isCreating ? 'Create User' : 'Edit User'}</h2>
            {error && <div className="error-message">{error}</div>}
            <div className="edit-form">
              <div className="form-group">
                <label>First Name</label>
                <input
                  type="text"
                  name="first_name"
                  value={editingUser.first_name}
                  onChange={handleEditChange}
                />
              </div>
              <div className="form-group">
                <label>Last Name</label>
                <input
                  type="text"
                  name="last_name"
                  value={editingUser.last_name}
                  onChange={handleEditChange}
                />
              </div>
              <div className="form-group">
                <label>Username</label>
                <input
                  type="text"
                  name="username"
                  value={editingUser.username}
                  onChange={handleEditChange}
                />
              </div>
              <div className="form-group">
                <label>Email</label>
                <input
                  type="email"
                  name="email"
                  value={editingUser.email}
                  onChange={handleEditChange}
                />
              </div>
              <div className="form-group">
              <label>Password</label>
              <input
                type="password"
                name="password"
                value={editingUser.password || ''}
                onChange={handleEditChange}
              />
              <PasswordRequirements requirements={passwordRequirements} />
            </div>
              <div className="form-group">
                <label>Role</label>
                <select
                  name="groups"
                  value={editingUser.groups[0] || ''}
                  onChange={handleEditChange}
                >
                  <option value="">Select a role</option>
                  {Object.entries(groups).map(([id, name]) => (
                    <option key={id} value={parseInt(id, 10)}>{name}</option>
                  ))}
                </select>
              </div>
            </div>
            <div className="edit-buttons">
              <button className="cancel-btn" onClick={() => {
                setIsEditing(false);
                setIsCreating(false);
                setEditingUser(null);
                setError('');
              }}>Cancel</button>
              <button className="save-btn" onClick={handleSave}>Save</button>
            </div>
          </div>
        </div>
      )}
      {isDeleting && (
        <div className="delete-alert">
          <div className="delete-alert-content">
            <h2>⚠ Alert</h2>
            <p>Do you want to delete {deletingUser.first_name} {deletingUser.last_name}?</p>
            <div className="delete-buttons">
              <button className="cancel-btn" onClick={() => setIsDeleting(false)}>Cancel</button>
              <button className="ok-btn" onClick={confirmDelete}>Ok</button>
            </div>
          </div>
        </div>
      )}
      {showConfirmation && (
        <div className="confirmation-popup">
          <div className="confirmation-popup-content">
            <h2>✓ Done</h2>
            <p>User deleted from system</p>
            <button className="ok-btn" onClick={handleConfirmationClose}>Ok</button>
          </div>
        </div>
      )}
      {showEditConfirmation && (
        <div className="confirmation-popup">
          <div className="confirmation-popup-content">
            <h2>✓ Success</h2>
            <p>
              {editActionType === 'create' ? 'User created successfully' : 'User updated successfully'}
            </p>
            <button className="ok-btn" onClick={() => setShowEditConfirmation(false)}>Ok</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserModule;