import React, { useState, useEffect } from 'react';
import '../../styles/job_module_style/JobModule.scss';
import Sidebar from '../../components/Sidebar';
import api from '../../utils/api';
import JobCard from '../../components/JobCard'; // Importa el componente JobCard
import icon1 from '../../assets/images/icon_delete.svg';
import icon2 from '../../assets/images/icon_edit.svg';
import icon3 from '../../assets/images/icon_add.svg';
import SearchIcon from '@mui/icons-material/Search';
import color_styles from '../../styles/_colors.scss';
import StartJobPopup from '../../components/StartJobPopup';
import { 
  TextField, 
  IconButton, 
  Box,
  Avatar,
  TablePagination
} from '@mui/material';
import InvoiceGenerationPopup from '../../components/InvoiceGenerationPopup';


const JobModule = () => {
  const [technicians, setTechnicians] = useState([]);
  const [filteredTechnicians, setFilteredTechnicians] = useState([]);
  const [techPage, setTechPage] = useState(0);
  const [techRowsPerPage, setTechRowsPerPage] = useState(7);

  const [jobs, setJobs] = useState([]);
  const [status, setStatus] = useState([]);
  const [machineries, setMachineries] = useState([]);
  const [groups, setGroups] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedJob, setSelectedJob] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [editingJob, setEditingJob] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const [deletingJob, setDeletingJob] = useState(null);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredJobs, setFilteredJobs] = useState([]);
  const [techSearchTerm, setTechSearchTerm] = useState('');
  const [confirmationMessage, setConfirmationMessage] = useState('');
  const [clients, setClients] = useState([]);
  const [selectedJobCard, setSelectedJobCard] = useState(null);
  const [startJobPopup, setStartJobPopup] = useState({ isOpen: false, job: null });
  const [showInvoice, setShowInvoice] = useState({ isOpen: false, job: null });

  // pagination of jobs
  const [currentPage, setCurrentPage] = useState(0);
  const jobsPerPage = 12; // 3 filas x 4 columnas

  const paginatedJobs = filteredJobs.slice(
    currentPage * jobsPerPage,
    (currentPage + 1) * jobsPerPage
  );

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };
  
  const totalPages = Math.ceil(filteredJobs.length / jobsPerPage);

  const getStatusIdByName = (statusName) => {
    const statusItem = status.find((item) => item.name === statusName);
    console.log('Status Item:', statusItem);
    return statusItem ? statusItem.id : null;
  };

  // UseEffect para obtener los datos de los technicians y jobs
  useEffect(() => {
    const fetchData = async () => {
      try {
        const [techResponse, jobsResponse, statusResponse, machineriesResponse, clientsResponse, groupsResponse] = await Promise.all([
          api.get('/users/'),
          api.get('/activities/'),
          api.get('/status/'),
          api.get('/machinery/'),
          api.get('/clients/'),
          api.get('/groups/')
        ]);
        setStatus(statusResponse.data);
        setGroups(groupsResponse.data);

        // obtener el id del technician
        const technicianGroupId = getGroupIdByName(groupsResponse.data, 'Technician');

        // filtar los usuarios que pertenezcan al grupo Technician
        const filteredTechnicians = techResponse.data.filter(user => 
          user.groups && user.groups.some(group => group === technicianGroupId)
        );

        // asignar todo
        setTechnicians(filteredTechnicians);
        setJobs(jobsResponse.data);
        setFilteredJobs(jobsResponse.data);
        setMachineries(machineriesResponse.data);
        setClients(clientsResponse.data);
        setLoading(false);
        console.log(statusResponse.data);
      } catch (err) {
        setError('Error fetching data');
        // Datos de prueba en caso de error
        setTechnicians([]);
        setJobs([]);
        setFilteredJobs([]);
        setClients([]);
        setMachineries([]);
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const getJobTechnician = async (jobId) => {
    try {
      const response = await api.get(`/activity-users/?id_activity=${jobId}`);
      if (response.data && response.data.length > 0) {
        const activityUser = response.data[0];
        // Only return the technician ID if the association is active
        return activityUser.is_active ? activityUser.id_user : '';
      }
      return '';
    } catch (err) {
      console.error('Error fetching technician for job:', err);
      return '';
    }
  };

  const deactivateTechnician = async (jobId) => {
    try {
      const response = await api.get(`/activity-users/?id_activity=${jobId}`);
      if (response.data && response.data.length > 0) {
        const activityUserId = response.data[0].id;
        await api.post(`/activity-users/${activityUserId}/deactivate/`);
        console.log('Technician deactivated successfully');
      }
    } catch (err) {
      console.error('Error deactivating technician:', err);
      throw err;
    }
  };

  const handleEdit = async () => {
    if (selectedJobCard) {
      try {
        // Get the current technician assigned to this job
        console.log('Selected Job Card ID:', selectedJobCard.id);
        const response = await api.get(`/activities/${selectedJobCard.id}/users`);
        console.log('Response:', response.data);
        const technicianId = response.data && response.data.length > 0 && response.data[0].is_active 
          ? response.data[0].id_user.toString() // Use id_user instead of id
          : '';

        console.log('Setting technician ID:', technicianId); // Debug log

        setEditingJob({
          ...selectedJobCard,
          technician_id: technicianId
        });
        setIsEditing(true);
      } catch (err) {
        console.error('Error fetching technician for job:', err);
        setEditingJob({
          ...selectedJobCard,
          technician_id: ''
        });
        setIsEditing(true);
      }
    }
  };

  const handleCreate = () => {
    setEditingJob({
      id_client: '',
      id_machinery: '',
      id_status: '1',
      type: '',
      description: '',
      work_date: new Date().toISOString().split('T')[0],
      frequency: '',
      technician_id: ''
    });
    setIsCreating(true);
  };

  const handleDelete = () => {
    if (selectedJobCard) {
      setDeletingJob(selectedJobCard);
      setIsDeleting(true);
    }
  };

  const handleEditChange = (e) => {
    setEditingJob({ ...editingJob, [e.target.name]: e.target.value });
  };

  const handleSave = async () => {
    try {
      const jobData = {
        id_client: editingJob.id_client,
        id_machinery: editingJob.id_machinery,
        id_status: editingJob.technician_id ? 
          getStatusIdByName("Job Assigned") : 
          getStatusIdByName("Job Unassigned"),
        type: editingJob.type,
        description: editingJob.description,
        work_date: editingJob.work_date,
        frequency: editingJob.frequency
      };
      console.log('Job Data:', jobData);

      if (isCreating) {
        const createResponse = await api.post('/activities/', jobData);
        console.log('New Job Response:', createResponse.data);
        
        // Create activity-user association if technician was selected
        console.log('Editing Job Technician ID:', editingJob.technician_id);
        if (editingJob.technician_id) {
          const activityUserData = {
            id_user: editingJob.technician_id,
            id_activity: String(createResponse.data.id),
            last_work: false
          };
          console.log('Activity-User Data:', activityUserData);
          const activityUserResponse = await api.post('/activity-users/', activityUserData);
          console.log('Activity-User Association Response:', activityUserResponse.data);
        }
        
        setConfirmationMessage('Job created successfully');
      } else {
        // For editing, check if technician was removed
        const currentTechId = await getJobTechnician(editingJob.id);
        if (currentTechId && !editingJob.technician_id) {
          // Technician was removed, deactivate the association
          await deactivateTechnician(editingJob.id);
        } else if (editingJob.technician_id) {
          // Technician was added or changed
          const activityUserData = {
            id_user: editingJob.technician_id,
            id_activity: editingJob.id,
            last_work: false
          };
          await api.post('/activity-users/', activityUserData);
        }

        await api.put(`/activities/${editingJob.id}/`, jobData);
        setConfirmationMessage('Job updated successfully');
      }

      const response = await api.get('/activities/');
      setJobs(response.data);
      setFilteredJobs(response.data);
      setIsEditing(false);
      setIsCreating(false);
      setEditingJob(null);
      setShowConfirmation(true);
    } catch (err) {
      setError('Error saving data');
      console.error('Error:', err);
    }
  };

  const confirmDelete = async () => {
    try {
      await api.post(`/activities/${deletingJob.id}/deactivate/`);
      const response = await api.get('/activities/');
      setJobs(response.data);
      setFilteredJobs(response.data);
      setIsDeleting(false);
      setDeletingJob(null);
      setSelectedJob(null);
      setConfirmationMessage('Job deleted successfully');
      setShowConfirmation(true);
    } catch (err) {
      setError('Error deleting data');
    }
  };

  const handleTechnicianClick = async (techId) => {
    try {
      const isDeselecting = techId === selectedJob;
      setSelectedJob(isDeselecting ? null : techId);
      
      if (!isDeselecting && techId) {
        console.log('Fetching jobs for technician:', techId);
        
        // Get jobs for the selected technician using the new endpoint
        const response = await api.get(`/users/${techId}/activities/`);
        console.log('Technician jobs response:', response.data);
        
        // Update filtered jobs with the technician's jobs
        setFilteredJobs(response.data);
      } else {
        // If deselecting technician, show all jobs
        console.log('Deselected technician, showing all jobs');
        setFilteredJobs(jobs);
      }
      
      // Reset to first page when changing filters
      setCurrentPage(0);
    } catch (err) {
      console.error('Error fetching technician jobs:', err);
      setFilteredJobs(jobs); // On error, show all jobs
    }
  };

  const handleSearch = (e) => {
    const term = e.target.value.toLowerCase();
    setSearchTerm(term);
  
    const filtered = jobs.filter(job => {
      const jobTitle = job.type?.toLowerCase() || '';
      const jobDescription = job.description?.toLowerCase() || '';
      const machineryInfo = getMachineryInfoById(job.id_machinery)?.toLowerCase() || '';
  
      return (
        jobTitle.includes(term) || 
        jobDescription.includes(term) || 
        machineryInfo.includes(term)
      );
    });
  
    setFilteredJobs(filtered);
  };

  const handleTechnicianSearch = (e) => {
    setTechSearchTerm(e.target.value);
  };

  const getStatusColor = (job_status) => {
    switch (job_status) {
      case "Job Unassigned":
        return color_styles.jobUnassignedColor;
      case "Job Assigned":
        return color_styles.jobAssignedColor;
      case "Parts Ordered":
        return color_styles.jobPartsOrderedColor;
      case "In Process":
        return color_styles.jobInProcessColor;
      case "Repair Completed":
        return color_styles.jobRepairCompleteColor;
      case "Parts Approval":
        return color_styles.jobPartsApprovalColor;
      case "PO Required":
        return color_styles.jobPORequiredColor;
      case "MGMT Approval Needed":
        return color_styles.jobMGMTApprovalNeedColor;
      case "Job Closed":
        return color_styles.jobClosedColor;
      default:
        return "#757575"; // Color por defecto
    }
  };

  const getStatusNameById = (id_status) => {
    const select_status = status.find((item) => item.id === id_status);
    return select_status ? select_status.name : "Status not found";
  };

  const getGroupIdByName = (groups, groupName) => {
    const group = groups.find(group => group.name === groupName);
    return group ? group.id : null;
  };

  const handleTechPageChange = (event, newPage) => {
    setTechPage(newPage);
  };

  const handleTechRowsPerPageChange = (event) => {
    setTechRowsPerPage(parseInt(event.target.value, 10));
    setTechPage(0);
  };

  const getMachineryInfoById = (machineryId) => {
    console.log('Getting machinery info for ID:', machineryId);
    console.log('Available machineries:', machineries);
    
    const machinery = machineries.find((item) => item.id === machineryId);
    console.log('Found machinery:', machinery);
    
    if (machinery) {
      const info = `${machinery.unit} ${machinery.model} | ${machinery.serial_number}`;
      console.log('Returning machinery info:', info);
      return info;
    } else {
      console.log('Machinery not found for ID:', machineryId);
      return "Machinery not found";
    }
  };

  const handleJobCardClick = (job) => {
    setSelectedJobCard(selectedJobCard?.id === job.id ? null : job);
  };

  const handleJobCardDoubleClick = (job) => {
    // Get the status name for the job
    const jobStatus = getStatusNameById(job.id_status);
    const machineryInfo = getMachineryInfoById(job.id_machinery);
    
    // Get the client info
    const machinery = machineries.find(m => m.id === job.id_machinery);
    const client = clients.find(c => c.id === machinery?.id_client);
    
    console.log('Double click job data:', {
      job,
      status: jobStatus,
      machinery: machineryInfo,
      location: client?.to_ship_location
    });
    
    // Only show popup if status is "Job Assigned"
    if (jobStatus === "Job Assigned") {
      setStartJobPopup({ 
        isOpen: true, 
        job: {
          ...job,
          machinery: machineryInfo,
          location: client?.to_ship_location // Add the shipping location
        }
      });
    }
  };

  const handleStartJobCancel = () => {
    setStartJobPopup({ isOpen: false, job: null });
  };

  const handleStartJobConfirm = () => {
    setShowInvoice({ isOpen: true, job: startJobPopup.job });
    setStartJobPopup({ isOpen: false, job: null });
  };

  const handleInvoiceBack = () => {
    setShowInvoice({ isOpen: false, job: null });
  };

  const handleCreateInvoice = () => {
    // Add invoice creation logic here
    setShowInvoice({ isOpen: false, job: null });
  };

  // Add this useEffect to filter technicians whenever techSearchTerm or technicians array changes
  useEffect(() => {
    const filtered = technicians.filter(tech => {
      const fullName = `${tech.first_name} ${tech.last_name}`.toLowerCase();
      return fullName.includes(techSearchTerm.toLowerCase());
    });
    setFilteredTechnicians(filtered);
  }, [techSearchTerm, technicians]);

  return (
    <div className="job-module-container">
      <Sidebar userType="admin" />
      <div className="job-module">

        <div className="module-title">
          <h1>Job Module</h1>
        </div>
        
        <div className="main-container"> 

          {/* ---------------------------------------------------------------------- Display Technician*/}
          <div className="technician-container">

            {/* ----------------- SearchTechnician*/}
            <div className="technician-search">
              <TextField
                fullWidth
                placeholder="Search technicians..."
                variant="outlined"
                InputProps={{
                  endAdornment: (
                    <IconButton className="search-button">
                      <SearchIcon />
                    </IconButton>
                  ),
                }}
                className="tech-search-input"
                onChange={handleTechnicianSearch}
                value={techSearchTerm}
              />
            </div>

            {/* ----------------- Display Technician*/}
            <div className="technicians-panel">
              <h2>Technicians</h2>
              <div className="technicians-list">
                {filteredTechnicians
                  .slice(techPage * techRowsPerPage, techPage * techRowsPerPage + techRowsPerPage)
                  .map(tech => (
                    <div 
                      key={tech.id}
                      className={`technician-card ${selectedJob === tech.id ? 'selected' : ''}`}
                      onClick={() => handleTechnicianClick(tech.id)}
                    >
                      <Avatar className="tech-avatar">
                        {`${tech.first_name[0]}${tech.last_name[0]}`}
                      </Avatar>
                      <div className="tech-info">
                        <h3>{`${tech.first_name} ${tech.last_name}`}</h3>
                        <div className="job-tags">
                          <span className="no-jobs">No assigned jobs</span>
                        </div>
                      </div>
                    </div>
                ))}
              </div>
              <TablePagination
                component="div"
                count={filteredTechnicians.length}
                page={techPage}
                onPageChange={handleTechPageChange}
                rowsPerPage={techRowsPerPage}
                onRowsPerPageChange={handleTechRowsPerPageChange}
                rowsPerPageOptions={[5, 7, 12]}
              />
            </div>
          </div>

          {/* ---------------------------------------------------------------------- Display Jobs*/}
            <div className="jobs-container">
              {/* ----------------- Search Job*/}
              <Box className="action-container">
                <TextField
                  fullWidth
                  placeholder="Search..."
                  variant="outlined"
                  InputProps={{
                    endAdornment: (
                      <IconButton className="search-button">
                        <SearchIcon />
                      </IconButton>
                    ),
                  }}
                  className="search-input"
                  onChange={handleSearch}
                />
                <IconButton 
                  className="delete-button" 
                  onClick={handleDelete}
                  disabled={!selectedJobCard}
                >
                  <img src={icon1} alt="Delete" />
                </IconButton>
                <IconButton 
                  className="edit-button" 
                  onClick={handleEdit}
                  disabled={!selectedJobCard}
                >
                  <img src={icon2} alt="Edit" />
                </IconButton>
                <IconButton className="add-button" onClick={handleCreate}>
                  <img src={icon3} alt="Add" />
                </IconButton>
              </Box>

              {/* ----------------- Display Technician*/}
              <div className="jobs-panel">
                {filteredJobs.length === 0 ? (
                  <div className="no-jobs-message">
                    <h3>No Jobs Found</h3>
                    <p>There are currently no jobs matching your search criteria.</p>
                  </div>
                ) : (
                  <div className="jobs-grid">
                    {paginatedJobs.map((job) => (
                      <JobCard
                        key={job.id}
                        jobName={job.type}
                        status={getStatusNameById(job.id_status)}
                        description={job.description}
                        machinery={getMachineryInfoById(job.id_machinery)}
                        date={job.work_date}
                        statusColor={getStatusColor(getStatusNameById(job.id_status))}
                        isSelected={selectedJobCard?.id === job.id}
                        onClick={() => handleJobCardClick(job)}
                        onDoubleClick={() => handleJobCardDoubleClick(job)}
                      />
                    ))}
                  </div>
                )}
              </div>

              {/* ----------------- Pagination Component */}
              <div className="pagination">
                <button
                  disabled={currentPage === 0}
                  onClick={() => handlePageChange(currentPage - 1)}
                >
                  Previous
                </button>
                <span>
                  Page {currentPage + 1} of {totalPages}
                </span>
                <button
                  disabled={currentPage === totalPages - 1}
                  onClick={() => handlePageChange(currentPage + 1)}
                >
                  Next
                </button>
              </div>
              
          </div>
        </div>
        

        {(isEditing || isCreating) && (
          <div className="edit-popup">
            <div className="edit-popup-content">
              <h2>{isCreating ? 'Create Job' : 'Edit Job'}</h2>
              {error && <div className="error-message">{error}</div>}
              <div className="edit-form">
                <div className="form-row">
                  <div className="form-group">
                    <label>Client & Location</label>
                    <select
                      name="id_client"
                      value={editingJob.id_client}
                      onChange={handleEditChange}
                    >
                      <option value="">Select client and location</option>
                      {clients.map(client => (
                        <option key={client.id} value={client.id}>
                          {`${client.client_name} - ${client.to_ship_location}`}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="form-group">
                    <label>Machinery</label>
                    <select
                      name="id_machinery"
                      value={editingJob.id_machinery}
                      onChange={handleEditChange}
                    >
                      <option value="">Select machinery</option>
                      {machineries.map(machine => (
                        <option key={machine.id} value={machine.id}>
                          {`${machine.unit} - ${machine.model} - ${machine.serial_number}`}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="form-row">
                  <div className="form-group">
                    <label>Type of Service</label>
                    <input
                      type="text"
                      name="type"
                      value={editingJob.type}
                      onChange={handleEditChange}
                    />
                  </div>
                  <div className="form-group">
                    <label>Frequency</label>
                    <select
                      name="frequency"
                      value={editingJob.frequency}
                      onChange={handleEditChange}
                    >
                      <option value="">Select frequency</option>
                      <option value="daily">Daily</option>
                      <option value="weekly">Weekly</option>
                      <option value="biweekly">Biweekly</option>
                      <option value="monthly">Monthly</option>
                      <option value="quarterly">Quarterly</option>
                      <option value="unique">Unique</option>
                    </select>
                  </div>
                </div>

                <div className="form-row">
                  <div className="form-group">
                    <label>Technician</label>
                    <select
                      name="technician_id"
                      value={editingJob.technician_id}
                      onChange={handleEditChange}
                    >
                      <option value="">Select technician</option>
                      {technicians.map(tech => (
                        <option key={tech.id} value={tech.id}>
                          {`${tech.first_name} ${tech.last_name}`}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="form-group">
                    <label>Date</label>
                    <input
                      type="date"
                      name="work_date"
                      value={editingJob.work_date}
                      onChange={handleEditChange}
                    />
                  </div>
                </div>

                <div className="form-group full-width">
                  <label>Description</label>
                  <textarea
                    name="description"
                    value={editingJob.description}
                    onChange={handleEditChange}
                    rows="4"
                  />
                </div>
              </div>

              <div className="edit-buttons">
                <button className="cancel-btn" onClick={() => {
                  setIsEditing(false);
                  setIsCreating(false);
                  setEditingJob(null);
                  setError('');
                }}>Cancel</button>
                <button className="save-btn" onClick={handleSave}>Save</button>
              </div>
            </div>
          </div>
        )}

        {isDeleting && (
          <div className="delete-alert">
            <div className="delete-alert-content">
              <h2>⚠ Alert</h2>
              <p>Do you want to delete this job?</p>
              <div className="delete-buttons">
                <button className="cancel-btn" onClick={() => setIsDeleting(false)}>Cancel</button>
                <button className="ok-btn" onClick={confirmDelete}>Ok</button>
              </div>
            </div>
          </div>
        )}

        {showConfirmation && (
          <div className="confirmation-popup">
            <div className="confirmation-popup-content">
              <h2>✓ Done</h2>
              <p>{confirmationMessage}</p>
              <button className="ok-btn" onClick={() => setShowConfirmation(false)}>Ok</button>
            </div>
          </div>
        )}

        <StartJobPopup
          isOpen={startJobPopup.isOpen}
          job={startJobPopup.job}
          onCancel={handleStartJobCancel}
          onConfirm={handleStartJobConfirm}
        />

        <InvoiceGenerationPopup
          isOpen={showInvoice.isOpen}
          job={showInvoice.job}
          onBack={handleInvoiceBack}
          onCreateInvoice={handleCreateInvoice}
        />
      </div>
    </div>
  );
};

export default JobModule;
